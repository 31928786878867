<template>
  <div class="page-custom page-independent container-fluid pt-4 pb-5">
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div class="col-xl-10 order-xl-1" v-if="loaded">
          <card shadow type="secondary">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t.translate("TTL_USER_INFORMATION") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <section v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) && profile.UserKey">
              <div class="pl-lg-4 pr-lg-4 mb-3">
                <div class="row">
                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_CURRENT_TERMS_VERSION") }}:</label
                    >
                    v.{{ currentTerms.TermsVersion }} ({{
                      $h.formatDateTime(currentTerms.TermsVersionDate)
                    }})
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_ACCEPTED_TERMS_VERSION") }}:</label
                    >
                    {{ profile.TermsVersion ? `v.${profile.TermsVersion}` : "---" }}
                    <span v-if="profile.TermsVersion">
                      ({{ $h.formatDateTime(profile.TermsVersionDate) }})</span
                    >
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_CURRENT_PRIVACY_VERSION") }}:</label
                    >
                    v.{{ currentTerms.PrivacyVersion }} ({{
                      $h.formatDateTime(currentTerms.PrivacyVersionDate)
                    }})
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_ACCEPTED_PRIVACY_VERSION") }}:</label
                    >
                    {{ profile.PrivacyVersion ? `v.${profile.PrivacyVersion}` : "---" }}
                    <span v-if="profile.PrivacyVersion"
                      >({{ $h.formatDateTime(profile.PrivacyVersionDate) }})</span
                    >
                  </div>
                </div>
              </div>
            </section>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("LBL_STATUS")
                    }}</label>
                    <div class="block form-group mb-3">
                      <el-radio v-model="profile.UserStatusCode" label="PENDING">{{
                        $t.translate("LBL_USER_PENDING")
                      }}</el-radio>
                      <el-radio v-model="profile.UserStatusCode" label="ACTIVE">{{
                        $t.translate("LBL_USER_ACTIVE")
                      }}</el-radio>
                      <el-radio v-model="profile.UserStatusCode" label="INACTIVE">{{
                        $t.translate("LBL_USER_INACTIVE")
                      }}</el-radio>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_FIRSTNAME")
                    }}</label>
                    <base-input
                      v-model="profile.FirstName"
                      :valid="!$h.isBlank(profile.FirstName)"
                      form-classes="mb-2"
                    />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_LASTNAME")
                    }}</label>
                    <base-input
                      v-model="profile.LastName"
                      :valid="!$h.isBlank(profile.LastName)"
                      form-classes="mb-2"
                    />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_EMAIL")
                    }}</label>
                    <base-input
                      v-model="profile.Email"
                      :valid="
                        !$h.isBlank(profile.Email) && $h.validateEmail(profile.Email)
                      "
                      type="email"
                      form-classes="mb-2"
                    />
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_CANCEL") }}
      </el-button>
      <el-button type="primary" class="" @click="saveChanges">
        {{ $t.translate("BTN_CONFIRM") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref } from "vue";
import { helpers } from "@/utils/helpers";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";

export default {
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let currentTerms = ref({});

    let OriginalEmailAddress = ref(null);

    let profile = reactive({
      UserKey: route?.params?.userkey || null,
      FirstName: "",
      LastName: "",
      Email: "",

      UserStatusCode: "",
    });

    const saveChanges = async () => {
      if (helpers.isSubmitting()) return;
      let IsChanging = false;

      try {
        if (profile.Email != OriginalEmailAddress.value) {
          IsChanging = true;

          await ElMessageBox.confirm(
            translation.translate("MSG_WARNING_CHANGE_EMAIL_ADDRESS"),
            translation.translate("TTL_WARNING"),
            {
              confirmButtonText: translation.translate("BTN_OKAY"),
              cancelButtonText: translation.translate("BTN_CANCEL"),
              type: "warning",
            }
          );
        }

        let res = await post("/user/sub/update", { ...profile });
        if (isValidResponse(res)) {
          helpers.showMessage({
            Code: "MSG_PROFILE_UPDATED",
            Message: "Profile successfully updated!",
          });
          router.goBack();
        }
      } catch (err) {
        //err
        if (IsChanging) {
          profile.Email = OriginalEmailAddress.value;
        }
      }
    };

    onMounted(async () => {
      if (route?.params?.userkey) {
        let res = await get("/user/view", { UserKey: route.params.userkey });
        profile.Email = res?.User?.Email;
        profile.FirstName = res?.User?.FirstName;
        profile.LastName = res?.User?.LastName;
        profile.LastName = res?.User?.LastName;
        profile.UserStatusCode = res?.User?.UserStatusCode;

        OriginalEmailAddress.value = res?.User?.Email;

        profile.TermsVersion = res?.User?.TermsVersion;
        profile.TermsVersionDate = res?.User?.TermsVersionDate;
        profile.PrivacyVersion = res?.User?.PrivacyVersion;
        profile.PrivacyVersionDate = res?.User?.PrivacyVersionDate;
      }

      let {
        TermsVersion,
        TermsVersionDate,
        PrivacyVersion,
        PrivacyVersionDate,
      } = await helpers.getTCVersion();

      currentTerms.value = {
        TermsVersion,
        TermsVersionDate,
        PrivacyVersion,
        PrivacyVersionDate,
      };

      loaded.value = true;
    });

    return {
      currentTerms,
      loaded,
      profile,
      saveChanges,
    };
  },
};
</script>
